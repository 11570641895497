"use strict";

class SSKeyHandoverService {

    constructor($timeout, SSConfigService, SSHttpService, SSAwsService, SSUtilService, SSUserService, SSAlertService, SSConfirmationService, NgTableParams) {
        const self = this;

        self.SSConfigService = SSConfigService;
        self.SSHttpService = SSHttpService;
        self.SSAwsService = SSAwsService;
        self.SSUtilService = SSUtilService;
        self.SSUserService = SSUserService;
        self.SSAlertService = SSAlertService;
        self.SSConfirmationService = SSConfirmationService;
        self.NgTableParams = NgTableParams;

        self.KEY_HANDOVER_APPOINTMENT_STATUS_SCHEDULED = 10;
        self.KEY_HANDOVER_APPOINTMENT_STATUS_PENDING = 13;
        self.KEY_HANDOVER_APPOINTMENT_STATUS_SUCCESSFUL = 20;
        self.KEY_HANDOVER_APPOINTMENT_STATUS_EXPIRED = 30;
        self.KEY_HANDOVER_APPOINTMENT_STATUS_CANCELED = 40;

        self.KEY_HANDOVER_BUYER_TYPE_MAIN = 10;
        self.KEY_HANDOVER_BUYER_TYPE_NOMINEE = 20;

        self.APPOINTMENT_FILE_TYPE_LETTER_OF_AUTHORISATION = 10;

        self.IDENTITY_TYPE_SINGAPOREAN = 'singaporean';
        self.IDENTITY_TYPE_SINGAPORE_PR = 'singaporean_pr';
        self.IDENTITY_TYPE_FOREIGNER = 'foreigner';

        self.$timeout = $timeout;

        self.users = [];
        self.buyers = [];
        self.agencies = [];

        self.APPOINTMENTS_DATA = {};
        // self.updateStatusParams={};
        self.PROPERTY_DOCUMENT_FORMAT_PDF = SHOWSUITE.PROPERTY_DOCUMENT_FORMAT_PDF;
        self.PROPERTY_DOCUMENT_FORMAT_IMG = SHOWSUITE.PROPERTY_DOCUMENT_FORMAT_IMG;
        self.APPOINTMENT_FILE_TYPE_OTHERS = SHOWSUITE.APPOINTMENT_FILE_TYPE_OTHERS;

        self.init();
    }

    init() {
        const self = this;

        self.STEP_NUMBER = 0;
        self.SHOW_STEP = {1: false, 2: false, 3: false};
        // Default 'max_appointment_days' 
        self.max_appointment_days = 30;

        self.limit = 10;
        self.current = 1;

        self.initStatuses();
        self.prepareConstants();

        self.pageChanged = function(newPage) {
            self.getKeyHandoverAppointments(self.searchFilter, newPage);
        };

        self.$timeout(function() { $("select.dashboard-select#property-select").niceSelect('update'); });
    }

    prepareConstants(){
        const self = this;

        self.BUYER_TYPES = [
            {id: self.KEY_HANDOVER_BUYER_TYPE_MAIN, name: 'Main'},
            {id: self.KEY_HANDOVER_BUYER_TYPE_NOMINEE, name: 'Nominee'}
        ];

        self.IDENTITY_TYPES = [
            {id: self.IDENTITY_TYPE_SINGAPOREAN, name: 'Singaporean'},
            {id: self.IDENTITY_TYPE_SINGAPORE_PR, name: 'Singapore PR'},
            {id: self.IDENTITY_TYPE_FOREIGNER, name: 'Foreigner'}
        ];
    }

    getPropertySettings(url, callback) {
        const self = this;
 
        if(!url){
            console.log('Invalid url to get property settings.');
            return;
        }

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if(response.data && response.data.property_settings){

                // Get Key Handover Settings
                self.key_handover_settings = self.getSetting(response.data.property_settings, 'key_handover_settings');
            
                if(self.key_handover_settings){
                    if(self.key_handover_settings.value && self.key_handover_settings.value.blackout_days){
                        $.each(self.key_handover_settings.value.blackout_days, function(index, blackout_day){
                            if(blackout_day.date){
                                blackout_day.date = moment(moment(blackout_day.date, 'YYYY-MM-DD HH:mm:ss')).utc().format('DD/MM/YYYY');
                            }
                            if(blackout_day.start_time && blackout_day.end_time){
                                blackout_day.start_time = (moment.utc(blackout_day.start_time, 'HH:mm')).local().format('HH:mm');
                                blackout_day.end_time = (moment.utc(blackout_day.end_time, 'HH:mm')).local().format('HH:mm');
                            }
                        });
                    }

                    if(self.key_handover_settings.value && self.key_handover_settings.value.week_days){

                        $.each(self.key_handover_settings.value.week_days, function(i, day){
                            if(!day.closed){
                                $.each(day.slots, function(j, slot){
                                    if(slot.start_time && slot.end_time){
                                        slot.start_time = (moment.utc(slot.start_time, 'HH:mm')).local().format('HH:mm');
                                        slot.end_time = (moment.utc(slot.end_time, 'HH:mm')).local().format('HH:mm');
                                    }
                                });
                            }
                        });
                    }

                    self.max_appointment_days = self.key_handover_settings.value.max_appointment_days? parseInt(self.key_handover_settings.value.max_appointment_days)+5 : 1;
                    // All appointments to exclude the booked one for new appointment
                    self.getAllAppointments(self.max_appointment_days);
                }
            }

            callback({success: true, key_handover_settings: self.key_handover_settings});
        });
    }

    clearSearchFilters(){
        const self = this;

        self.start_date = null;
        self.search_text = "";

        self.getKeyHandoverAppointments(self.KEY_HANDOVER_APPOINTMENT_STATUS_SCHEDULED);
    }

    getAllAppointments(max_appointment_days, showLoading = true){
        const self = this;

        if(!max_appointment_days){
            max_appointment_days = self.max_appointment_days;
        }

        let startDate = moment();
        let endDate = moment().add(max_appointment_days, 'days');

        let url = 'sec/developer/key-handover/appointments';
        let statuses = [self.KEY_HANDOVER_APPOINTMENT_STATUS_SCHEDULED, self.KEY_HANDOVER_APPOINTMENT_STATUS_PENDING].join();
        
        let params = {
            property_id: self.property? self.property.id : null,
            response_type: 1,
            status: statuses,
            start_date: startDate.format('YYYY-MM-DD') + " 00:00",
            end_date: endDate.format('YYYY-MM-DD') + " 00:00",
            include: 'documents, all_documents'
        };

        loading(showLoading);
        this.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (response.success && response.data) {
                self.APPOINTMENTS_DATA = {};
                self.allAppointments = self.getFormattedAppointments(response.data.key_handover_appointments, true);
                self.prepareData();
            }
        });
    }

    getKeyHandoverAppointments(status, page = 1){
        const self = this;

        self.offset = (page * self.limit) - self.limit;
        if(page) {
            self.current = page;
        }

        let searchDate = self.start_date? moment(self.start_date): null;
        let startDate = searchDate? searchDate.format('YYYY-MM-DD HH:mm:ss') : null;
        let endDate = searchDate? searchDate.add(24, 'hours').format('YYYY-MM-DD HH:mm:ss') : null;

        let url = 'sec/developer/key-handover/appointments';
        let params = {
            property_id: self.property? self.property.id : null,
            response_type: 1,
            include: 'buyers, units, documents, primary_address, all_documents',
            limit: self.limit,
            offset: self.offset,
            send_total_count: 1,
            status: status?status:self.searchFilter,
            search_text: self.search_text,
            start_date: startDate,
            end_date: endDate
        };

        loading(true);
        this.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (response.success && response.data) {
                self.buyers = response.data.buyers;
                self.users = response.data.users;

                self.appointments = self.getFormattedAppointments(response.data.key_handover_appointments);
                self.appointmentStats = response.data.appointment_stats;

                // self.prepareData();

                self.count = response.data.total_count;
                self.total_count = response.data.total_count;
                self.pages = Math.ceil(self.count / self.limit);
                self.selectedPage = Math.floor(self.offset / self.limit) + 1;
                self.pagesArr = [];

                for (var i = 0; i < self.pages; i++) {
                    self.pagesArr.push(i);
                }
            }
            
            // callback({appointments: self.appointments, total_count: total_count, APPOINTMENTS_DATA: self.APPOINTMENTS_DATA});
        });
    }

    getFilteredAppointments(status){
        const self = this;

        self.searchFilter = status;
        // self.start_date = '';
        self.end_date = '';

        self.offset = 0;
        self.selectedPage = 1;
        self.getKeyHandoverAppointments(status);
    }

    getFormattedAppointments(appointments, initAppointmentRecords = false){
        const self = this;

        if(!appointments){
            return null;
        }

        $.each(appointments, function(index, appointment){

            appointment.start_date = self.formatUtcToLocalDate(appointment.start_date);
            appointment.end_date = self.formatUtcToLocalDate(appointment.end_date);

            if(appointment.status == self.KEY_HANDOVER_APPOINTMENT_STATUS_SCHEDULED 
                && (self.searchFilter == '' || self.searchFilter == self.KEY_HANDOVER_APPOINTMENT_STATUS_EXPIRED)
                && moment(appointment.end_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm') < moment().format('YYYY-MM-DD HH:mm')){
                appointment.status = self.KEY_HANDOVER_APPOINTMENT_STATUS_EXPIRED;
            }

            var status = self.getStatus(appointment.status);

            appointment.status_name = status.name;
            appointment.status_class = status._class;

            appointment.created_by_user = self.getUserName(appointment.created_by);

            try{
                // Process buyers
                if(!appointment.hasOwnProperty("buyers")){
                    appointment["buyers"] = [];
                }
                $.each(appointment.buyer_ids, function(idx, buyer_id){
                    let buyer = self.buyers.find(x => x.id == buyer_id);
                    if(buyer){
                        buyer.legal_name = self.getBuyerName(buyer_id, self.buyers);
                        if(buyer.type == self.KEY_HANDOVER_BUYER_TYPE_NOMINEE){

                            self.getPresignedUrl(appointment.id, buyer, 'authorisation_letter_uri', function (presignedUrl) {
                                buyer.show_authorisation_letter_uri = presignedUrl;
                            });
                            
                            if(buyer.identity_type == self.IDENTITY_TYPE_FOREIGNER){
                                // Passport image is saved as 'nric_front' in case of 'foreigner'
                                self.getPresignedUrl(appointment.id, buyer, 'nric_front_uri', function (presignedUrl) {
                                    buyer.show_nric_front_uri = presignedUrl;
                                });
                            }
                            else{
                                self.getPresignedUrl(appointment.id, buyer, 'nric_front_uri', function (presignedUrl) {
                                    buyer.show_nric_front_uri = presignedUrl;
                                });
                                self.getPresignedUrl(appointment.id, buyer, 'nric_back_uri', function (presignedUrl) {
                                    buyer.show_nric_back_uri = presignedUrl;
                                });
                            }
                        }
                        appointment.buyers.push(buyer);
                    }
                });
                // Re-arrange buyers by type e.g type: 10 first then type: 20
                appointment.buyers = _.sortBy(appointment.buyers, 'type');
            }
            catch(ex){
                console.log(ex);
            }

            // Prepare data for associative array to enable/disable slots
            var date = moment(appointment.start_date).format('YYYY-MM-DD');
            var time = moment(appointment.start_date).format('HH:mm:ss')+ '-' +moment(appointment.end_date).format('HH:mm:ss');
            appointment.date = date;
            appointment.time = time;

            if(initAppointmentRecords){
                self.updateAppointmentsRecord(date, time);
            }
        });

        return appointments;
    }

    prepareData(){
        const self = this;

        self.available_days = [];
        self.blackout_days = {};

        let max_appointment_days = self.key_handover_settings.value.max_appointment_days;
        max_appointment_days = max_appointment_days? parseInt(max_appointment_days) : 7;

        if(self.key_handover_settings.value.slots_enabled_after_days)
            max_appointment_days += parseInt(self.key_handover_settings.value.slots_enabled_after_days);

        let blackout_days = self.key_handover_settings.value.blackout_days;
        blackout_days = blackout_days? angular.copy(blackout_days) : null;

        if(blackout_days){
            $.each(blackout_days, function(index, day){
                if(self.blackout_days[day.date]){
                    self.blackout_days[day.date].slots.push(
                        {start_time: day.start_time, end_time: day.end_time});
                }
                else {
                    self.blackout_days[day.date] = {date: day.date, slots: []};
                    self.blackout_days[day.date].slots.push(
                        {start_time: day.start_time, end_time: day.end_time});
                }
            });
        }

        // Number of tables
        self.tables = [];

        let number_of_tables = self.key_handover_settings.value.number_of_tables;
        number_of_tables = number_of_tables? parseInt(number_of_tables) : 1;

        for(var index = 1; index <= number_of_tables; index++){
            self.tables.push({id: index.toString(), name: "SLOT " + index});
        }

        let week_days = self.key_handover_settings.value.week_days;

        if(max_appointment_days){

            for(var index = 0; index < max_appointment_days; index++){
                
                var today = moment();
                var date = today.add(index, 'days');

                let day = date.day();
                let week_day = week_days[day];

                // Total slots = time_slots * number_of_tables
                var slot_times = self.getSlotTimes(date);
                var expired_slots = slot_times.filter(x => x.is_expired == true);
                var blackout_slots = slot_times.filter(x => x.is_blackout == true);

                var total_slots = (slot_times.length - expired_slots.length - blackout_slots.length) * number_of_tables;

                self.available_days.push({
                    id: index+1, 
                    closed: week_day.closed,
                    name: date.format('dddd, Do MMMM YYYY'),
                    value: date.format('YYYY-MM-DD'),
                    is_blackout_day: self.isBlackoutDay(date),
                    all_slots: self.getAllSlots(date),
                    available_slots_count: self.getAvailableSlotsCount(date, total_slots)
                });

            }
        }
    }

    updateAppointmentsRecord(date, time){
        const self = this;

        if(self.APPOINTMENTS_DATA[date]){
            self.APPOINTMENTS_DATA[date] = self.APPOINTMENTS_DATA[date] + 1;
        }
        else{
            self.APPOINTMENTS_DATA[date] = 1;
        }

        if(self.APPOINTMENTS_DATA[date + '-' + time]){
            self.APPOINTMENTS_DATA[date + '-' + time] = self.APPOINTMENTS_DATA[date + '-' + time] + 1;
        }
        else{
            self.APPOINTMENTS_DATA[date + '-' + time] = 1;
        }

    }

    addNewDocument(params){
        const self = this;

        if(!params.buyer_documents)
            params.buyer_documents = [];

        params.buyer_documents.push({});
    }

    removeDocument(index, params){
        const self = this;

        if(params.buyer_documents.length == 1){
            params.buyer_documents[index] = {};
            return;
        }

        let documents = [];
        
        delete params.buyer_documents[index];
        $.each(params.buyer_documents, function(i, document){
            if(document)
                documents.push(document);
        });

        params.buyer_documents = documents;
    }

    addNewBuyer(){
        const self = this;

        // User can not add more than 6 buyers
        let newBuyer = {type: self.KEY_HANDOVER_BUYER_TYPE_MAIN, phone_contact: "+65"};

        if(self.appointmentParams.buyers.length >= 6){
            // Can't add more than 6 buyers
            // self.appointmentErrors.buyers = "Can not add more than 6 buyers.";
            self.SSAlertService.danger("Can not add more than 6 buyers.");
            return;
        }
        
        self.appointmentParams.buyers.push(newBuyer);

        // Update phone input
        setTimeout(function(){
            let index = self.appointmentParams.buyers.length-1;
            if($("#phone_"+index).length)
                self.SSUserService.maskPhoneNumber(self, 'ctrl.SSKeyHandoverService.', 'phone_'+index);
        });
    }

    removeBuyer(index){
        const self = this;

        self.appointmentParams.buyers.splice(index, 1);
    }

    addRepresentative(buyer){
        const self = this;

        if(!buyer) return;

        let representative = {type: self.KEY_HANDOVER_BUYER_TYPE_NOMINEE, phone_contact: "+65"};
        representative.buyer_documents = [{}];
        
        buyer.authorised_representative = representative;

        // Update phone input
        setTimeout(function(){
            let index = self.appointmentParams.buyers.length-1;
            if($("#phone_"+(index+1)).length)
                self.SSUserService.maskPhoneNumber(self, 'ctrl.SSKeyHandoverService.', 'phone_'+(index+1));
        });
    }

    removeRepresentative(buyer){
        const self = this;

        if(!buyer) return;

        delete buyer.authorised_representative;
    }

    getSetting(settings, setting_name){
        const self = this;

        if(!(settings || setting_name)){
            return null;
        }

        let setting = {};

        try{
            setting = settings.find(x => x.name == setting_name);

            if(setting && setting.value){
                if(typeof setting.value == "string"){
                    setting.value = JSON.parse(setting.value);
                }
                else{
                    setting.value = setting.value;
                }
            }
        }
        catch(ex){
            console.log(ex);
        }

        return setting;
    }

    getSlotTimes(date){
        const self = this;

        // Slot period in minutes
        let slot_period = self.key_handover_settings.value.slot_period;
        slot_period = slot_period? parseInt(slot_period) : 60;

        let week_days = self.key_handover_settings.value.week_days;
        week_days = (week_days && week_days.length > 0)? week_days : null;

        if(!week_days) return null;

        if(typeof date == 'object' && !moment.isMoment(date)) 
            date = moment(date.value, 'YYYY-MM-DD');
        else if(typeof date == 'string')
            date = moment(date, 'YYYY-MM-DD');

        let day = date.day();

        let week_day = week_days[day];
        let slots = [];

        if(!week_day.closed){
            $.each(week_day.slots, function(index, slot){
                
                var start_time = moment(slot.start_time,'hh:mm a');
                var end_time = moment(slot.end_time,'hh:mm a');

                while(start_time.format('HH:mm:ss') < end_time.format('HH:mm:ss')){
                    var slot_info = {
                        id: index, 
                        date: date.format('YYYY-MM-DD'),
                        start_time: start_time.format('HH:mm:ss'),
                        name: self.SSUtilService.formatUtcToLocalTime(start_time),
                        end_time: start_time.add(slot_period, 'minutes').format('HH:mm:ss')
                    };

                    slot_info.is_blackout = self.isBlackoutSlot(date, slot_info.start_time, slot_info.end_time);
                    slot_info.is_expired = self.isExpiredSlot(date, slot_info.start_time, slot_info.end_time);
                    slot_info.is_booked = self.isBookedSlot(date, slot_info.start_time, slot_info.end_time);
                    
                    slots.push(slot_info);
                }
            });  
        }

        return slots;
    }

    getAllSlots(date){
        const self = this;

        if(!date) return;

        return self.getSlotTimes(date);
    }

    getAvailableSlotsCount(date, total_slots){
        const self = this;

        let availableSlots = total_slots;

        $.each(self.allAppointments, function(index, appointment){
            if(moment(date).format("YYYY-MM-DD") == moment(appointment.start_date).format("YYYY-MM-DD")){
                availableSlots--;
            }
        });

        return availableSlots;
    }

    getTotalSlotsCount(date){
        const self = this;

        if(!date) return null;

        return self.getSlotTimes(date).length;
    }

    isBlackoutDay(date, start_time = null, end_time = null){
        const self = this;

        let flag = false;

        if(!date) return false;

        if(typeof date == 'string'){
            date = moment(date);
        }

        if(self.blackout_days && !self.blackout_days[date.format('DD/MM/YYYY')]){
            return flag;
        }

        let blackout_day = self.blackout_days[date.format('DD/MM/YYYY')];

        if(blackout_day && start_time && end_time 
            && blackout_day.slots[0].start_time 
            && blackout_day.slots[0].end_time){

            flag = self.isBlackoutSlot(date, start_time, end_time);
        }
        if(blackout_day && !blackout_day.slots[0].start_time && !blackout_day.slots[0].end_time){
            // If day exist but start/end time is null then whole day is blackout.
            flag = true;
        }

        return flag;
    }

    isBlackoutSlot(date, start_time, end_time){
        const self = this;

        let flag = false;
        if(!date) return false;

        if(typeof date == 'string'){
            date = moment(date);
        }

        if(!start_time || !end_time
            || (self.blackout_days && !self.blackout_days[date.format('DD/MM/YYYY')])){
            return flag;
        }

        let blackout_day = self.blackout_days[date.format('DD/MM/YYYY')];

        if(blackout_day && blackout_day.slots[0].start_time && blackout_day.slots[0].end_time
            && start_time && end_time){

            start_time = typeof start_time == 'string'? moment(start_time, 'HH:mm') : start_time;
            end_time = typeof end_time == 'string'? moment(end_time, 'HH:mm') : end_time;

            $.each(blackout_day.slots, function(index, slot){
                if(slot.start_time && slot.end_time){
                    if(start_time >= moment(slot.start_time, 'HH:mm') && end_time.subtract(1, "minutes") <= moment(slot.end_time, 'HH:mm')){
                        flag = true;
                        return;
                    }
                }
            });
        }

        return flag;
    }

    isExpiredSlot(date, start_time, end_time){
        const self = this;

        if(!date || !start_time || !end_time){
            return false;
        }

        start_time = typeof start_time == 'string'? moment(start_time, 'HH:mm:ss') : start_time;

        if(moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
            && start_time.format('HH:mm:ss') < moment().format('HH:mm:ss')){
            return true;
        }
        else{
            return false;
        }
    }

    isBookedSlot(date, start_time, end_time){
        const self = this;

        if(!date || !start_time || !end_time){
            return false;
        }

        let number_of_slots = self.key_handover_settings.value.number_of_tables? self.key_handover_settings.value.number_of_tables : 1;
        let bookedSlots = self.APPOINTMENTS_DATA[date+"-"+start_time+"-"+end_time];

        return bookedSlots == number_of_slots;
    }

    getSlotStatus(date, start_time, end_time){
        const self = this;

        // Slot Status -> 'Booked', 'Expired', 'Blackout'
        if(self.isBookedSlot(date, start_time, end_time)) return 'Booked';
        if(self.isExpiredSlot(date, start_time, end_time)) return 'Expired';
        if(self.isBlackoutDay(date, start_time, end_time)) return 'Blackout';

        return 'Available';
    }

    getSlotClass(slot){
        const self = this;

        let cls = '';

        // If slot falls in 'blackout_day'
        if(self.isBlackoutDay(self.selected_date.value, slot.start_time, slot.end_time)
            || self.isExpiredSlot(self.selected_date.value, slot.start_time, slot.end_time)){
            return 'disabled btn-outline-danger';
        }

        let number_of_slots = self.key_handover_settings.value.number_of_tables;
        let bookedSlots = self.APPOINTMENTS_DATA[self.selected_date.value+'-'+slot.start_time+'-'+slot.end_time];

        if(self.APPOINTMENTS_DATA[self.selected_date.value+'-'+slot.start_time+'-'+slot.end_time]
            && self.appointmentParams.time == slot.start_time+'-'+slot.end_time
            && self.appointmentParams.status != self.KEY_HANDOVER_APPOINTMENT_STATUS_CANCELED
            && self.appointmentParams.status != self.KEY_HANDOVER_APPOINTMENT_STATUS_EXPIRED
            && self.appointmentParams.date == slot.date){
            // It will highlight the time of current selected slot
            cls += 'highlight';
        }
        else if(self.APPOINTMENTS_DATA[self.selected_date.value+'-'+slot.start_time+'-'+slot.end_time]){
            if(bookedSlots == number_of_slots){
                cls += 'disabled btn-outline-danger';
            }
        }

        return cls;
    }

    getBookedSlotClass(slot){
        const self = this;

        let cls = '';
        
        if(slot.is_expired || slot.is_blackout){
            return 'disabled';
        }

        let number_of_slots = self.key_handover_settings.value.number_of_tables;
        let bookedSlots = self.APPOINTMENTS_DATA[self.selected_date.value+'-'+slot.start_time+'-'+slot.end_time];

        if(self.APPOINTMENTS_DATA[self.appointmentParams.date+'-'+slot.start_time+'-'+slot.end_time]
            && slot.date == self.appointmentParams.date
            && self.appointmentParams.time == slot.start_time+'-'+slot.end_time
            && self.appointmentParams.status != self.KEY_HANDOVER_APPOINTMENT_STATUS_CANCELED){
            // It will highlight the time of current selected slot
            cls = 'highlight';
        }
        if(self.APPOINTMENTS_DATA[self.selected_date.value+'-'+slot.start_time+'-'+slot.end_time]){
            if(bookedSlots == number_of_slots){
                cls += ' text-warning';
            }
        }

        return cls;
    }

    saveUserAppointment(status) {
        const self = this;

        self.update_appointment_status = status;

        if(!self.isValidAppointmentInput()) return;
        self.appointmentErrors = {};

        self.confirmationMessage = 'Are you sure you want to proceed to update the appointment changes?';
        if (self.appointmentParams.status == self.KEY_HANDOVER_APPOINTMENT_STATUS_PENDING
            && status == self.KEY_HANDOVER_APPOINTMENT_STATUS_SCHEDULED
        ) {
            self.confirmationMessage = 'Are you sure you want to update and approve this appointment? If you proceed, the changes will be updated and also the appointment will be placed under Scheduled section.';
        } else if (self.appointmentParams.status == self.KEY_HANDOVER_APPOINTMENT_STATUS_CANCELED
            || self.appointmentParams.status == self.KEY_HANDOVER_APPOINTMENT_STATUS_EXPIRED
        ) {
            self.confirmationMessage = 'Are you sure you want to update the appointment? If you proceed the changes will be updated. And also the appointment will be placed under Scheduled section.';
        }

        $('#keyhandoverAppointmentConfirmationModal').modal('show');
    }

    _saveUserAppointment() {
        const self = this;

        let url = 'sec/developer/key-handover/appointments';
        let params = self.getAppointmentParams();

        params.reschedule = self.reschedule;

        if (self.newAppointment) {
            let unitForNewAppointment = self.SSUtilService.getFromLocalStorage('unitForNewAppointment');
            if (unitForNewAppointment) {
                params.unit_id = unitForNewAppointment.id;
            }
        }

        // 'status' should be updated to SCHEDULED for CANCELED and EXPIRED cases
        if (params.status === self.KEY_HANDOVER_APPOINTMENT_STATUS_CANCELED
            || params.status === self.KEY_HANDOVER_APPOINTMENT_STATUS_EXPIRED
        ) {
            params.status = self.KEY_HANDOVER_APPOINTMENT_STATUS_SCHEDULED;
        } else if (params.status === self.KEY_HANDOVER_APPOINTMENT_STATUS_PENDING) {
            if (self.update_appointment_status) {
                params.status = self.update_appointment_status;
            }
        }

        /** WEB-2798 Keyhandover Collection App: Appointment Address Support **/
        if(params.primary_address){
            params.primary_address_line1 = params.primary_address.line1;
            params.primary_address_line2 = params.primary_address.line2 != null ? params.primary_address.line2 : '';
            params.primary_address_country = params.primary_address.country;
            params.primary_address_city = params.primary_address.city != null ? params.primary_address.city : '';
            params.primary_address_unit_no = params.primary_address.unit_no != null ? params.primary_address.unit_no : '';
            params.primary_address_district = params.primary_address.district != null ? params.primary_address.district : '';
            params.primary_address_postcode = params.primary_address.postcode;
            params.primary_address_residence_type = params.primary_address.residence_type ? params.primary_address.residence_type : null;

        }

        let responseHandler = function (response) {
            loading(false);
            $('#keyhandoverAppointmentConfirmationModal').modal('hide');
            
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.SSAlertService.success('Key handover appointment updated successfully.');

            self.APPOINTMENT_VIEW_MODE = false;
            self.appointmentEditMode = false;
            self.newAppointment = false;

            self.SSUtilService.deleteFromLocalStorage('newAppointment');
            self.SSUtilService.deleteFromLocalStorage('unitForNewAppointment');

            self.getKeyHandoverAppointments(self.searchFilter);
        };

        loading(true);
        if (params.id) {
            url += '/' + params.id;
            self.SSHttpService.putAPIRequest(url, params).then(responseHandler);
        } else {
            self.SSHttpService.postAPIRequest(url, params).then(responseHandler);
        }
    }

    getAppointmentParams(){
        const self = this;

        let params = angular.copy(self.appointmentParams);

        if(params.buyers){
            params.buyer_ids = [];
            $.each(params.buyers, function(index, buyer){
                params.buyer_ids.push(buyer.id);
            });

            params.buyer_ids = params.buyer_ids? params.buyer_ids.toString() : null;
        }

        params.start_date = self.getParamsDate(params.start_date);
        params.end_date = self.getParamsDate(params.end_date);

        params.buyers = JSON.stringify(params.buyers); 
        
        return params;
    }

    isValidAppointmentInput(){
        const self = this;

        let isValid = true;
        self.appointmentErrors = {};

        if(!self.appointmentParams.buyers || self.appointmentParams.buyers.length == 0
            || !self.appointmentParams.buyers.find(x => x.type == self.KEY_HANDOVER_BUYER_TYPE_MAIN)){
            self.appointmentErrors.buyers = "Please add at least one buyer.";
            isValid = false;
        }

        if(!self.isValidBuyerInput()) return false;

        return isValid;
    }

    deleteAppointment(appointment){
        const self = this;

        if(!appointment){
            return;
        }

        self.SSConfirmationService.getConfirmed(
            'Confirmation!',
            'Are you sure you want to delete this appointment? The appointment will be deleted along with all of purchasers details and uploaded documents.',
            self.SSConfirmationService.TYPE_DEFAULT,
            function () {
                
                let url = 'sec/developer/key-handover/appointments/'+ appointment.id +'/delete';

                loading(true);
                self.SSHttpService.getAPIRequest(url).then(function (response) {
                    loading(false);

                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }

                    if(response.success){
                        self.SSAlertService.success('Appointment deleted successfully.');
                        self.appointments = $.grep(self.appointments, function(obj){
                            return appointment.id != obj.id;
                        });
                    }
                });

        });
    }

    getPresignedUrl(appointmentId, buyer, document, callback){
        const self = this;

        if(!(document && document.id)) return;

        let url = 'sec/developer/key-handover/appointments/'+ appointmentId +'/presigned-url';
        let params = {
            'buyer_id': buyer? buyer.id : null,
            'document_id': document.id
        };

        //loading(true);
        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            //loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (callback) {
                callback(response.data.presigned_url);
            } else {
                window.open(response.data.presigned_url, '_blank');
            }
        });
    }

    setStepOneViewMode(appointment, step_number = null){
        const self = this;

        self.EDIT_MODE_DISABLED = false;

        if(self.SSUtilService.getFromLocalStorage('newAppointment')){
            self.SSUtilService.deleteFromLocalStorage('newAppointment');
        }

        self.appointmentParams = {};
        self.appointmentErrors = {};

        if(appointment){
            self.newAppointment = false;
            self.appointmentParams = angular.copy(appointment);
        }
        else{
            self.appointmentParams.status = self.KEY_HANDOVER_APPOINTMENT_STATUS_SCHEDULED
        }

        self.appointmentParams.property_id = self.property.id;

        self.APPOINTMENT_VIEW_MODE = true;
        self.slot_times = null;

        if(appointment && step_number != 1) {
            self.selected_date = {name: self.formatDate3(appointment.start_date)};
            self.selected_slot = {name: self.SSUtilService.formatUtcToLocalTime(moment(appointment.start_date,'hh:mm a'))};

            step_number = 3;
        }

        if(self.newAppointment){
            self.appointmentEditMode = true;
            self.appointmentParams.unit = self.SSUtilService.getFromLocalStorage('unitForNewAppointment');
        }

        self.toggleStepsView(step_number);
    }

    setStepTwoViewMode(day, step_number){
        const self = this;

        self.selected_date = day;
        self.slot_times = self.getSlotTimes(day);

        self.toggleStepsView(step_number);
    }

    setStepThreeViewMode(slot, table_no, step_number){
        const self = this;

        self.selected_slot = slot;

        self.appointmentParams.start_date = self.selected_date.value + " " + slot.start_time;
        self.appointmentParams.end_date = self.selected_date.value + " " + slot.end_time;
        self.appointmentParams.table_no = table_no;

        if(!self.appointmentParams.buyers 
            || (self.appointmentParams.buyers && self.appointmentParams.buyers.length == 0))
            self.appointmentParams.buyers = [{type: self.KEY_HANDOVER_BUYER_TYPE_MAIN}];

        self.updateNiceSelect();
        self.toggleStepsView(step_number);

        setTimeout(function(){
            $.each(self.appointmentParams.buyers, function(i, buyer){
                if($("#phone_"+i).length)
                    self.SSUserService.maskPhoneNumber(self, 'ctrl.SSKeyHandoverService.', 'phone_'+i);
            });
        });
    }

    toggleStepsView(step_number){
        const self = this;

        self.SHOW_STEP[1] = false;
        self.SHOW_STEP[2] = false;
        self.SHOW_STEP[3] = false;

        self.SHOW_STEP[step_number] = true;
        self.STEP_NUMBER = step_number;
    }
    
    goBack(step_number){
        const self = this;

        self.STEP_NUMBER--;

        if(step_number != undefined)
            self.STEP_NUMBER = parseInt(step_number);

        if(self.STEP_NUMBER == 0){
            self.APPOINTMENT_VIEW_MODE = false;
        }
        else{
            self.toggleStepsView(self.STEP_NUMBER);
        }
    }

    backPage() {
        const self = this;

        self.selectedPage--;
        self.offset = self.offset - self.limit;
        self.getKeyHandoverAppointments(self.searchFilter);
    }

    nextPage() {
        const self = this;

        self.selectedPage++;
        self.offset = self.offset + self.limit;
        self.getKeyHandoverAppointments(self.searchFilter);
    }

    gotoPage(pageNum){
        const self = this;

        self.offset = parseInt(pageNum) * self.limit;
        self.getKeyHandoverAppointments(self.searchFilter);
    }

    formatDate3(date){
        const self = this;

        return moment(date).format('dddd, Do MMMM YYYY');
    }

    getParamsDate(date){
        const self = this;

        try{
            if(date != '' && date != null) {
                // Split date and time on the basis of white-space
                var date_time = date.split(" ");

                var date = date_time[0];
                var time = '';

                if(date_time.length > 1){
                    time = date_time[1];
                    // Server accepts time as 'hh:mm:ss' but jQuery timepicker is 'hh:mm'
                    if(time.split(':').length == 2){
                        time = time + ':00';
                    }
                }

                if(date.includes("-")){
                    var date_format = date + ' ' + time;
                }
                else{
                    var res = date.split("/", 3);
                    var date_format = res[2] + '-' + res[1] + '-' + res[0] + ' ' + time;
                }

                if(date_format) {
                    // var localDate = new Date(self.parseDate(date_format));
                    var utcFormat = moment(date_format).utc().format('YYYY-MM-DD HH:mm:ss');
                }

                return utcFormat;
            }
            else{
                return null;
            }
        }
        catch(ex){
            console.log(ex);
        }
    }

    uploadBuyerDocument(files, buyer, index){
        const self = this;

        if (!files || files.length < 1) return;
        let file = files[0];
        let format_type = null;

        if (file.type.indexOf("application/pdf") !== -1) {
            format_type = "pdf";
        } else if (file.type.indexOf("image") !== -1) {
            format_type = "image";
        } else {
            self.SSAlertService.danger('Error!', 'Please upload PDF or image file only');
            return;
        }

        // // Show 'image' preview
        // if(format_type == 'image'){
        //     var reader = new FileReader();
        //     reader.onload = function(event) {
        //         buyer['show_'+ file_type] = event.target.result;
        //     }
        //     // when the file is read it triggers the onload event above.
        //     reader.readAsDataURL(file);
        // }
        // else{
        //     buyer['show_'+ file_type] = '/images/pdf-placeholder.png';
        // }

        let policyUrl = 'sec/developer/key-handover/appointments/s3-policy-document?property_id='+self.property.id;

        loading(true);
        self.SSAwsService.uploadAPIRequest(file, policyUrl, function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if(!buyer.buyer_documents){
                buyer.buyer_documents = [];
                buyer.buyer_documents[index] = {};
            }

            buyer.buyer_documents[index].file_uri = response.config.data.key;
            buyer.buyer_documents[index].name = file.name.split('.')[0];
        });
    }

    updateNiceSelect(){
        setTimeout(function () {
            $("select").niceSelect('update');
        });
    }

    isValidBuyerInput(){
        const self = this;

        let isValid = true;
        self.editBuyerErrors = {};

        $.each(self.appointmentParams.buyers, function(i, buyer){
            self.editBuyerErrors[i] = {};
            if(!buyer.legal_name){
                isValid = false;
                self.editBuyerErrors[i].legal_name = "Legal name is required.";
            }
            if(!buyer.email_contact){
                isValid = false;
                self.editBuyerErrors[i].email_contact = "Email address is required.";
            }
            if(!buyer.phone_contact){
                isValid = false;
                self.editBuyerErrors[i].phone_contact = "Phone number is required.";
            }
        });

        return isValid;
    }

    getBuyerName(buyer_id, buyers){
        const self = this;

        let name = '--';

        let _buyers = (buyers && buyers.length > 0)? buyers : self.buyers;

        if(!buyer_id || !_buyers){
            return name;
        }

        let buyer = _buyers.find(x => x.id == buyer_id);

        if(buyer && (buyer.legal_name)){
            name = buyer.legal_name;
        }

        return name;
    }

    getUserName(user_id){
        const self = this;

        let userName = '--';

        if(!user_id || !self.users){
            return userName;
        }

        let user = self.users.find(x => x.id == user_id);
        if(user){
            userName = self.getUserFullName(user);
        }

        return userName;
    }

    removeUser(user_id, user_type){
        const self = this;

        if(!user_id || !user_type){
            return;
        }

        if(user_type == 'buyer'){
            self.appointmentParams.buyers = $.grep(self.appointmentParams.buyers, function(obj){
                return user_id != obj.id;
            });
            self.selectedBuyer = self.appointmentParams.buyers.length? self.appointmentParams.buyers[0] : null;
        }
    }

    getStatus(status_id){
        const self = this;

        if(!status_id){
            return {name: '--', _class: ''};
        }

        let status = self.statuses.find(x => x.id == status_id);
        if(status){
            return status;
        }

        return {name: '--', _class: ''};
    }

    initStatuses(){
        const self = this;

        self.statuses = [
            {id: self.KEY_HANDOVER_APPOINTMENT_STATUS_SCHEDULED, name: 'SCHEDULED', _class: 'text-primary'},
            {id: self.KEY_HANDOVER_APPOINTMENT_STATUS_PENDING, name: 'PENDING', _class: 'text-primary'},
            {id: self.KEY_HANDOVER_APPOINTMENT_STATUS_SUCCESSFUL, name: 'KEY COLLECTED', _class: 'text-success'},
            {id: self.KEY_HANDOVER_APPOINTMENT_STATUS_CANCELED, name: 'CANCELLED', _class: 'text-warning'},
            {id: self.KEY_HANDOVER_APPOINTMENT_STATUS_EXPIRED, name: 'EXPIRED', _class: 'text-danger'}
        ];
    }

    getParamsDate(date){
        const self = this;

        try{
            if(date != '' && date != null) {
                // Split date and time on the basis of white-space
                var date_time = date.split(" ");

                var date = date_time[0];
                var time = '';

                if(date_time.length > 1){
                    time = date_time[1];
                    // Server accepts time as 'hh:mm:ss' but jQuery timepicker is 'hh:mm'
                    if(time.split(':').length == 2){
                        time = time + ':00';
                    }
                }

                if(date.includes("-")){
                    var date_format = date + ' ' + time;
                }
                else{
                    var res = date.split("/", 3);
                    var date_format = res[2] + '-' + res[1] + '-' + res[0] + ' ' + time;
                }

                if(date_format) {
                    // var localDate = new Date(self.parseDate(date_format));
                    var utcFormat = moment(date_format).utc().format('YYYY-MM-DD HH:mm:ss');
                }

                return utcFormat;
            }
            else{
                return null;
            }
        }
        catch(ex){
            console.log(ex);
        }
    }

    formatUtcToLocalDate(date){
        const self = this;

        var local = date;

        try{
            if(typeof date === 'string'){
                // Convert 'date-time' string to UTC 'date-time' object
                var stillUtc = moment.utc(date).toDate();

                // Now convert UTC to local 'date-time' object
                var local = moment(moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss'));  
            }
        }
        catch(ex){
            console.log(ex);
        }

        return local;
    }

    updateFilter() {
        const self = this;

        self.offset = 0;
        self.page = 1;
        self.getKeyHandoverAppointments(self.searchFilter, self.page);
    }

    openDocumentUploadPopup(){
        const self = this;
        self.upload_doc_obj = {};
        self.upload_doc_error = {};
        $('#uploadDocumentKeyHandoverModal').modal('show');
    }

    userSelectkeyHandOverDocument(file){
        const self = this;

        self.upload_doc_error.document = "";

        if (!file ) return;

        if (file.type.indexOf("application/pdf") !== -1) {
            self.upload_doc_obj.format_type = self.PROPERTY_DOCUMENT_FORMAT_PDF;
        } else {
            self.upload_doc_error.document = "Please upload PDF file only";
            return;
        }

        // Create a FileReader object
        const reader = new FileReader();

        // Set up the reader's onload event handler
        reader.onload = function(e) {
            // Get the image data URL
            self.upload_doc_obj.selected_file_url = e.target.result;
            // self.upload_doc_obj.selected_file_url_base64 = e.target.result.split(',')[1];

        };

        // Read the selected file as Data URL
        reader.readAsDataURL(file);

        self.upload_doc_obj.selected_file = file
        self.upload_doc_obj.name = file.name;
    }

    openDocumentForView(){
        const self = this;
        const newTab = window.open();
        newTab.document.write(
            `<iframe src="${self.upload_doc_obj.selected_file_url}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`
        );
        // window.open( self.upload_doc_obj.selected_file_url, '_blank');
    }

    isValidDocumentInput(){
        const self = this;

        let isValid = true;

        if(!self.upload_doc_obj.name){
            self.upload_doc_error.name = "Document name field is required";
            isValid = false;
        }
        if(!self.upload_doc_obj.selected_file){
            self.upload_doc_error.document = "Please select PDF file";
            isValid = false;
        }
        return isValid;
    }

    uploadDocument() {
        const self = this;
        self.upload_doc_error = {};
        if(!self.isValidDocumentInput()){
            return;
        }
        let file = self.upload_doc_obj.selected_file;

        let policyUrl = 'sec/developer/key-handover/appointments/s3-policy-document?property_id='+self.property.id;

        loading(true);
        self.SSAwsService.uploadAPIRequest(file, policyUrl, function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            self.upload_doc_obj.file_uri = response.config.data.key;
            self.createDocumentWithFileURI();
        });
    }



    createDocumentWithFileURI() {
        const self = this;
        let url = 'sec/developer/key-handover/appointments/'+ self.appointmentParams.id+ '/document';
        let params = {
            name: self.upload_doc_obj.name,
            type: self.APPOINTMENT_FILE_TYPE_OTHERS,
            file_uri: self.upload_doc_obj.file_uri,
            is_user_uploaded: 1
        };

        loading(true);
        self.SSHttpService.postAPIRequest(url, params).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            $('#uploadDocumentKeyHandoverModal').modal('hide');
            self.appointmentParams.documents.push(response.data.document);
            self.SSAlertService.success('Success!', 'File uploaded successfully.');

        });
    }

    getAppointmentDocuments(){
        const self = this;
        let url = 'sec/developer/key-handover/appointments/'+ self.appointmentParams.id+ '/documents';
        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            self.appointmentParams.documents = response.data.documents;
            self.SSAlertService.success('Success!', 'File uploaded successfully.');
        });
    }

    removeAppointmentDocument(document_id){
        const self = this;
        let url = 'sec/developer/key-handover/appointments/'+ self.appointmentParams.id+ '/document/'+document_id;
        loading(true);
        self.SSHttpService.deleteAPIRequest(url).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            let index = self.appointmentParams.documents.findIndex((element) => {return element.id === document_id;});
            self.appointmentParams.documents.splice(index, 1);
            self.SSAlertService.success('Success!', 'File remove successfully.');
        });
    }
}

SSKeyHandoverService.$inject = ['$timeout', 'SSConfigService', 'SSHttpService', 'SSAwsService', 'SSUtilService', 'SSUserService', 'SSAlertService', 'SSConfirmationService'];
app.service('SSKeyHandoverService', SSKeyHandoverService);

app.directive("datepicker", function () {

    function link(scope, element, attrs) {
        // CALL THE "datepicker()" METHOD USING THE "element" OBJECT.
        element.datepicker({
            //inline: true,
            dateFormat: "dd/mm/yy"
        });
    }

    return {
        require: 'ngModel',
        link: link
    };
});

// app.directive('dateformat', function (dateFilter) {
//     return {
//         require:'ngModel',
//         link:function (scope, elm, attrs, ctrl) {

//             var dateFormat = attrs['date'] || 'dd/MM/yyyy';

//             ctrl.$formatters.unshift(function (modelValue) {
//                 return dateFilter(modelValue, dateFormat);
//             });
//         }
//     };
// })
