"use strict";

class SSDateService {

    //===========================================
    // Date Formats
    dateFormat(date, format = null) {
        if (!date) {
            return;
        }

        let utcDate = moment.utc(date);

        if (utcDate._isValid) {
            let localDate = utcDate.local();
            if (format) {
                return localDate.format(format);
            } else {
                return localDate.toLocaleString();
            }
        }
    }

    dateFormatDefault(date) {
        return this.dateFormat(date);
    }

    dateFormat3(date) {
        return this.dateFormat(date, 'DD/MM/YYYY');
    }

    dateFormat4(date) {
        return this.dateFormat(date, 'YYYY-MM-DD');
    }

    dateFormat5(date) {
        return this.dateFormat(date, 'MMMM DD, YYYY');
    }

    //-------------------------------------------

    getFormatOptionForYear() {
        return {
            formatYear: 'yyyy',
            startingDay: 1,
            minMode: 'year'
        }
    }

    getFormatOptionForMonth() {
        return {
            formatYear: 'yyyy-MM',
            minMode: 'month'
        }
    }

    /**
     * Creates date object with local time from UTC date string.
     *
     * @param utcDateString format "YYYY-MM-DD hh:mm:ss"
     * @param defaultVal
     */
    toLocalDate(utcDateString, defaultVal) {
        if (!utcDateString) {
            return defaultVal;
        }
        utcDateString = utcDateString.trim();

        var exp = /^(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})$/;
        if (!exp.test(utcDateString)) {
            return defaultVal;
        }

        var dateArray = utcDateString.split(' ');

        var dateString = dateArray[0];
        var timeString = dateArray[1];

        dateArray = dateString.split('-');
        var timeArray = timeString.split(':');

        var year = dateArray[0];
        var month = dateArray[1];
        var day = dateArray[2];
        var hours = timeArray[0];
        var minutes = timeArray[1];
        var seconds = timeArray[2];

        return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
    }

    formateWithTimeAsNoon(date) {
        if (!date)
            return;

        if (date.indexOf('/') > -1) {
            let array = date.split('/', 3);
            date = array[2] + '-' + array[1] + '-' + array[0];
        }

        // Check if Time is already there with correct formatted date
        try {
            if (date.trim().indexOf(" ") > -1) {
                return date;
            }
        } catch (ex) {
            console.log(ex);
        }

        return date + ' 12:00:00';
    }

    getLocalTimezone(){
        let timezone = "Asia/Singapore";

        try{
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }catch (e) {
            console.warn("Unable to get local timezone. Default timezone will be used.", e);
        }
        return timezone;
    }
}

SSDateService.$inject = [];
app.service('SSDateService', SSDateService);