"use strict";

class SSAppService {


    /*
     *
     * USAGE:
     *
     * self.SSAppService.instance.selectedCountry.name
     *
     */

    constructor($log, $window, $localStorage, SSUtilService, SSHttpService, SSUserService, SSConfigService) {
        const self = this;

        self.$log = $log;
        self.$window = $window;
        self.$localStorage = $localStorage;
        self.SSUtilService = SSUtilService;
        self.SSHttpService = SSHttpService;
        self.SSUserService = SSUserService;
        self.SSConfigService = SSConfigService;
        self.appServiceObject = SSUtilService.defineStorageDefault('def_app_service');

        self.PROPERTY_PRODUCT_MARKETING_SUITE_1D = 0;//NULL
        self.PROPERTY_PRODUCT_MARKETING_SUITE_2D = 1;
        self.PROPERTY_PRODUCT_MARKETING_SUITE_3D = 2;
        self.PROPERTY_PRODUCT_MANAGEMENT_SUITE_BUYER_BOOKING = 3;
        self.PROPERTY_PRODUCT_MANAGEMENT_SUITE_AGENT_BOOKING = 4;

        self.UNIT_STATUS = {
            STATUS_DRAFT: 10,
            STATUS_LISTED: 20,
            STATUS_RESERVED: 30,
            STATUS_BOOKED: 40,
            STATUS_SOLD: 50,
            UNIT_STATUS_SPECIAL_RESERVED: 60,
        };

        //by default page status is not loaded, controller's job is to set it true after init() finish
        self.isPageLoaded = false;
        self.sharingProperty = null;
        self.sharingUnit = null;
        self.shareCode = null;

        self.instance = self.appServiceObject;

        self.historyPage = false;

        self.init();
    }

    init() {
        const self = this;
    }

    /**
     * This method checks the md5 hashcode from server, and validates it with the current saved config here.
     * If md5 are different, then fetches the latest config from server.
     */
    needServerConfig() {
        const self = this;

        self.checkIsOrganicOrigin();

        if (!self.appServiceObject.serverConfig) {
            self.getServerConfig();
        }

        self.SSHttpService.getAPIRequest('config-md5').then(
            function (responseObj) {
                if (responseObj instanceof Error) {
                    console.error(responseObj);
                    return;
                }
                self.appServiceObject.serverConfigMd5 = responseObj.data;
                self.getServerConfig();
            }
        );
    }

    /**
     * Returns config object.
     * And if there is some change on server, then also initiate the fetch call for latest config from server.
     *
     * @returns config object
     */
    getServerConfig() {
        const self = this;

        /* Check if need to fetch the latest config from server */
        if (!self.appServiceObject.serverConfig || self.appServiceObject.serverConfig.md5 !== self.appServiceObject.serverConfigMd5) {

            if (!self.serverConfigDownloading) {
                self.serverConfigDownloading = true;

                /* Fetch the latest config from server. */
                self.SSHttpService.getAPIRequest('config').then(
                    function (responseObj) {
                        if (responseObj instanceof Error) {
                            console.error(responseObj);
                            return;
                        }
                        self.setServerConfig(responseObj.data);
                        self.serverConfigDownloading = false;
                    }
                );
            }
        }

        // Return the current config object
        return self.appServiceObject.serverConfig;
    }

    setServerConfig(config) {
        const self = this;
        self.appServiceObject.serverConfig = config;

        //to set selected country
        self.getSelectedCountry();
    }

    getCountries() {
        const self = this;
        if (self.getServerConfig()) {
            return self.getServerConfig().countries;
        } else {
            return null;
        }
    }

    setSelectedCountry(countryObject) {
        const self = this;
        self.appServiceObject.selectedCountry = countryObject;
    }

    getSelectedCountry() {
        const self = this;
        if (!self.appServiceObject.selectedCountry) {
            if (self.getServerConfig()) {
                self.appServiceObject.selectedCountry = self.getServerConfig().countries[0];
            } else {
                self.appServiceObject.selectedCountry = null;
            }
        }
        return self.appServiceObject.selectedCountry;
    }

    getSelectedCountryRegions() {
        const self = this;
        let country = self.getSelectedCountry();
        if (country) {
            return country.regions;
        } else {
            return null;
        }
    }

    getCountry(countryCode) {
        const self = this;
        if (self.getServerConfig()) {
            return self.SSUtilService.findValueFromArray(self.getServerConfig().countries, 'country_code', countryCode);
        } else {
            return null;
        }
    }

    getCountryName(countryCode) {
        const self = this;
        let country = self.getCountry(countryCode);
        return country != null ? country.name : '';
    }

    getRegionName(countryCode, regionCode) {
        const self = this;
        let regionName = null;

        if (countryCode != null) {
            let country = self.getCountry(countryCode)
            if (country != null) {
                let region = self.SSUtilService.findValueFromArray(country.regions, 'code', regionCode);
                regionName = region != null ? region.name : null;
            }
            return regionName;
        }
    }

    getGenders() {
        const self = this;
        if (self.getServerConfig()) {
            return self.getServerConfig().genders;
        } else {
            return null;
        }
    }

    getPropertyTypes() {
        const self = this;
        if (self.getServerConfig()) {
            return self.getServerConfig().property_types;
        } else {
            return null;
        }
    }

    getPropertyTypeName(typeId) {
        const self = this;
        let type = null;
        if (self.getServerConfig()) {
            type = self.SSUtilService.findValueFromArray(self.getServerConfig().property_types, 'id', typeId);
        }
        return type ? type.name : '';
    }

    getUnitCategories() {
        const self = this;

        if (self.getServerConfig()) {
            return self.getServerConfig().unit_categories;
        }
        return null;
    }

    getUnitCategoryName(categoryId) {
        const self = this;
        let category = null;
        if (self.getServerConfig()) {
            category = self.SSUtilService.findValueFromArray(self.getServerConfig().unit_categories, 'id', categoryId);
        }
        return category ? category.name : '';
    }

    getUnitTypes() {
        const self = this;
        if (self.getServerConfig()) {
            return self.getServerConfig().unit_types;
        } else {
            return null;
        }
    }

    getUnitType(typeId) {
        const self = this;
        let type = null;
        if (self.getServerConfig()) {
            type = self.SSUtilService.findValueFromArray(self.getServerConfig().unit_types, 'id', typeId);
        }
        return type;
    }

    getUnitTypeName(typeId) {
        const self = this;
        let type = self.getUnitType(typeId);
        return type ? type.name : '';
    }

    getUnitTypeShort(typeId) {
        const self = this;
        let type = self.getUnitType(typeId);
        return type ? type.short : '';
    }

    getUnitTypeSubtypeName(typeId, categoryId, withParenthesis = false) {
        const self = this;
        let categoryNameArr = self.getUnitCategoryName(categoryId).split(" ");
        let typeNameArr = self.getUnitTypeName(typeId).split(" ");
        let subTypeName = '';
        if (typeNameArr.length > 0 && categoryNameArr.length > 0) {
            _.forEach(typeNameArr, function (typeNameItem) {
                if (categoryNameArr.indexOf(typeNameItem) === -1) {
                    subTypeName += typeNameItem + ' ';
                }
            });
            subTypeName = subTypeName.trim();
            if (withParenthesis && subTypeName) {
                subTypeName = '(' + subTypeName + ')';
            }
            return subTypeName.trim();
        } else {
            return '';
        }
    }

    getFloorPlanTypes() {
        const self = this;

        let type = null;
        if (self.getServerConfig()) {
            return self.getServerConfig().unit_floor_plans;
        }
        return null;
    }

    getFloorPlanName(typeId) {
        const self = this;
        let type = null;
        if (self.getServerConfig()) {
            type = self.SSUtilService.findValueFromArray(self.getServerConfig().unit_floor_plans, 'id', typeId);
        }
        return type ? type.name : '';
    }

    getKitchenTypes() {
        const self = this;
        let type = null;
        if (self.getServerConfig()) {
            return self.getServerConfig().unit_kitchen;
        }
        return null;
    }

    getKitchenTypeName(typeId) {
        const self = this;
        let type = null;
        if (self.getServerConfig()) {
            type = self.SSUtilService.findValueFromArray(self.getServerConfig().unit_kitchen, 'id', typeId);
        }
        return type ? type.name : '';
    }

    getFlooringTypes() {
        const self = this;
        let type = null;
        if (self.getServerConfig()) {
            return self.getServerConfig().unit_flooring;
        }
        return null;
    }

    getTilingTypes() {
        const self = this;
        let type = null;
        if (self.getServerConfig()) {
            return self.getServerConfig().unit_tiles;
        }
        return null;
    }

    getUnitStatuses() {
        const self = this;
        if (self.getServerConfig()) {
            return self.getServerConfig().unit_statuses;
        } else {
            return null;
        }
    }

    getUnitStatusName(typeId) {
        const self = this;
        let type = null;
        if (self.getServerConfig()) {
            type = self.SSUtilService.findValueFromArray(self.getServerConfig().unit_statuses, 'id', typeId);
        }
        return type ? type.name : '';
    }

    getAssetUrl(uri) {
        const self = this;
        if (!uri || uri.indexOf('placehold') !== -1) {
            return uri;
        }

        if (self.getServerConfig()) {
            return self.getServerConfig().assets_host + '/' + uri;
        } else {
            return null;
        }
    }

    /**
     * Others
     */

    getSoldPercentage(property) {
        const self = this;

        let percentage = 0,
            totalSize = self.getTotalUnits(property),
            draft = self.getDraftUnits(property),
            sold = self.getSoldUnits(property);
        let total = totalSize - draft;

        if (total > 0) {
            if (total == sold) {
                percentage = 100;
            } else {
                percentage = Math.round(sold / total * 100);
                percentage = percentage > 99 ? 99 : percentage;
            }
        }

        return percentage;
    }

    getSoldPercentageForGraph(property) {
        const self = this;
        let percentage = self.getSoldPercentage(property);
        return percentage > 99.99 ? 99.99 : percentage;
    }

    getSoldPercentageFormatted(property) {
        const self = this;
        return self.getSoldPercentage(property) + '%';
    }

    getRemainingUnits(property) {
        const self = this;
        return self.getTotalUnits(property) - self.getSoldUnits(property) - self.getDraftUnits(property);
    }

    getSoldUnits(property) {
        const self = this;
        let soldUnits = 0;
        if (property && property.unit_statuses && self.UNIT_STATUS.STATUS_SOLD in property.unit_statuses) {
            soldUnits = property.unit_statuses[self.UNIT_STATUS.STATUS_SOLD];
        }
        return soldUnits;
    }
    getDraftUnits(property) {
        const self = this;
        let draftUnits = 0;
        if (property && property.unit_statuses && self.UNIT_STATUS.STATUS_DRAFT in property.unit_statuses) {
            draftUnits = property.unit_statuses[self.UNIT_STATUS.STATUS_DRAFT];
        }
        return draftUnits;
    }

    getTotalUnits(property) {
        let totalUnits = 0;
        if (property) {
            _.each(property.unit_statuses, function (status) {
                totalUnits += status;
            });
        }
        return totalUnits;
    }

    getLaunchedUnits(property) {
        const self = this;

        return self.getTotalUnits(property) - self.getDraftUnits(property);
    }

    getFormattedConstructionDate(constructionDate) {
        return this.SSUtilService.formatDate1(constructionDate);
    }

    getFormattedConstructionDate2(constructionDate) {
        return this.SSUtilService.formatDate2(constructionDate);
    }

    getFormattedValue(val) {
        let formattedVal = 0;
        if (val && val > 0) {
            formattedVal = parseInt(val);
        } else {
            return val;
        }
        return formattedVal.toLocaleString();
    }

    getFormattedValueWithDecimalPlaces(val, decimalPlaces) {
        let formattedVal = 0;
        if (val && val > 0) {
            if (val % 1 === 0) {
                formattedVal =  val; // Return the integer as is
            } else {
                formattedVal =  parseFloat(val.toFixed(decimalPlaces)); // Otherwise, format to decimalPlaces = 2 decimal places and remove trailing zeros
            }
        } else {
            return val;
        }
        return formattedVal.toLocaleString();
    }

    getFormattedFloatValue(val) {
        let formattedVal = 0;

        if (val && val > 0) {
            formattedVal = parseInt(val * 100) / 100;
        } else {
            return val;
        }
        return formattedVal.toLocaleString();
    }

    getFormattedPercentageValue(val) {
        return this.getFormattedFloatValue(val) + ' %';
    }

    getFormattedPercentageFloatValue(val) {
        let formattedAmount = 0;

        if (null != val && !isNaN(val)) {
            formattedAmount = parseFloat(val);
        }
        
        return formattedAmount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' %';
    }

    getFormattedAmount(amount, countryCode) {
        const self = this;

        let currency_symbol = self.getCurrencySymbol(countryCode);
        
        let formattedAmount = 0;
        if (null != amount && !isNaN(amount)) {
            formattedAmount = parseInt(amount);
        }

        return currency_symbol + formattedAmount.toLocaleString();
    }

    getFormattedAmountWithDecimal(amount, countryCode) {
        const self = this;

        let currency_symbol = self.getCurrencySymbol(countryCode);
        
        let formattedAmount = 0;
        if (null != amount && !isNaN(amount)) {
            formattedAmount = parseFloat(amount);
        }
        
        return currency_symbol + formattedAmount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    getAmountWithDecimal(amount) {
        let formattedAmount = 0;
        if (null != amount && !isNaN(amount)) {
            formattedAmount = parseFloat(amount);
        }
        
        return formattedAmount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    getAmount(amount) { // Without Decimal
        let formattedAmount = 0;
        if (null != amount && !isNaN(amount)) {
            formattedAmount = parseFloat(amount);
            formattedAmount = Math.round(amount);
        }

        return formattedAmount.toLocaleString();
    }

    getFormattedAmountOrRangeWithDecimal(unit, countryCode) {
        const self = this;

        let currency_symbol = self.getCurrencySymbol(countryCode);

        let formattedAmount = 0;
        if (null != unit.price && !isNaN(unit.price)) {
            formattedAmount = parseInt(unit.price);
        }
        if (formattedAmount == 0 && unit.start_price && unit.end_price) {
            let start_price = unit.start_price;
            let end_price = unit.end_price;
            return currency_symbol + start_price.toLocaleString() +' - '+ currency_symbol + end_price.toLocaleString();
        }
        return formattedAmount ? currency_symbol + formattedAmount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '-';
    }

    getFormattedAmountOrRange(unit, countryCode) {
        const self = this;

        let currency_symbol = self.getCurrencySymbol(countryCode);

        let formattedAmount = 0;
        if (null != unit.price && !isNaN(unit.price)) {
            formattedAmount = parseInt(unit.price);
        }
        if (formattedAmount == 0 && unit.start_price && unit.end_price) {
            let start_price = unit.start_price;
            let end_price = unit.end_price;
            return currency_symbol + start_price.toLocaleString() +' - '+ currency_symbol + end_price.toLocaleString();
        }
        return formattedAmount ? currency_symbol + formattedAmount.toLocaleString() : '-';
    }

    getCurrencySymbol(countryCode){
        const self = this;

        // Default should be SGD
        let symbol = 'S$';

        let country = self.SSConfigService.getCountry(countryCode);
        if(country){
            symbol = country.currency_symbol;
            try{
                // If symbol is unicode then it will be converted.
                // If symbol is some normal text/char then it will not be changed
                symbol = JSON.parse(`["${symbol}"]`)[0];
            }
            catch(ex){
                console.log('Error: can not convert unicode. ', ex);
            }
        }

        return symbol;
    }

    getRoundOffValue(value, countryCode){
        const self = this;

        if(!value){
            return '--';
        }

        return self.getFormattedAmount(Math.round(value), countryCode);
    }

    getPropertyTypeIconClass(property)
    {
        let iconClass = '';
        if (property && property.type) {
            if (property.type == SHOWSUITE.PROPERTY_TYPE_PRIVATE_CONDO) {
                iconClass = 'icon-condo';
            } else if (property.type == SHOWSUITE.PROPERTY_TYPE_EXECUTIVE_CONDO) {
                iconClass = 'icon-ec';
            } else if (property.type == SHOWSUITE.PROPERTY_TYPE_LANDED) {
                iconClass = 'icon-landed';
            }
        }

        return iconClass;
    }

    isUnitBooked(unit) {
        let self = this;
        return unit && unit.status == self.UNIT_STATUS.STATUS_BOOKED;
    }

    isUnitSold(unit) {
        let self = this;
        return unit && unit.status == self.UNIT_STATUS.STATUS_SOLD;
    }

    getUnitStatusText(unitStatus) {
        let self = this;

        let statusText = '';
        if (unitStatus) {
            switch (unitStatus) {
                case self.UNIT_STATUS.STATUS_DRAFT:
                    statusText = "Draft";
                    break;
                case self.UNIT_STATUS.STATUS_LISTED:
                    statusText = "Listed";
                    break;
                case self.UNIT_STATUS.STATUS_RESERVED:
                case self.UNIT_STATUS.STATUS_BOOKED:
                    statusText = "Reserved";
                    break;
                case self.UNIT_STATUS.STATUS_SOLD:
                    statusText = "Sold";
                    break;
                case self.UNIT_STATUS.UNIT_STATUS_SPECIAL_RESERVED:
                    statusText = "Special Reserved";
                    break;
            }
        }
        return statusText;
    }

    getPropertyHeroThumbUrl(property) {
        const self = this;
        let thumb = null;

        if (property) {
            let assets = null;

            if (property.property_assets) {
                assets = property.property_assets;
            } else if (property.propertyAssets) {
                assets = property.propertyAssets;
            }

            if (assets) {
                _.forEach(assets, function (asset) {
                    if (asset.type == 2) {
                        thumb = asset.file_uri;
                    }
                });
            }
        }
        return self.getAssetUrl(thumb);
    }

    getPropertyAssetsForCarousel(property) {
        let assets = [];
        if (property && property.property_assets) {
            _.forEach(property.property_assets, function (asset) {
                if (asset.type == 2 || asset.type == 3) {
                    assets.push(asset);
                }
            });
        }
        return assets;
    }

    getUnitFloorPlanThumbUrl(unit) {
        const self = this;
        let thumb = null;
        if (unit && unit.unit_assets) {
            _.forEach(unit.unit_assets, function (asset) {
                if (asset.type == 1) {
                    thumb = asset.file_uri;
                }
            });
        }
        return self.getAssetUrl(thumb);
    }

    /**
     * Routes and redirections.
     */

    viewInvestmentDetails(saleId) {
        window.location.href = '/folio/investments/' + saleId;
    }

    viewProperty(propertyId) {
        window.location.href = '/' + propertyId;
    }

    viewUnits(propertyId,product_type,msg) {

        if(!product_type)
        {
            if(msg)
                $('#one_d_alert_poppup').modal('show');
            else
                $('#one_d_alert_poppup2').modal('show');
        }
        else
            window.location.href = '/' + propertyId + '/units';
    }

    viewUnit(propertyId, unitId) {
        window.location.href = '/' + propertyId + '/' + unitId;
    }

    viewUnitSelectionWithBuyer(propertyId, buyerId) {
        window.location.href = '/' + propertyId + '/eoi/' + buyerId + '/unitSelection';
    }

    viewUnitSelection(propertyId, buyerId) {
        let url = "";
        if (buyerId) {
            url = '/' + propertyId + '/eoi/' + buyerId + '/unitSelection';
        } else {
            url = '/' + propertyId + '/eoi' + '/unitSelection';
        }
        window.location.href = url;
    }

    loginWithFacebook() {
        const self = this;

        let currentUrl = self.SSUtilService.addOrUpdateUrlParam(window.location.href, 'social_auth', 'facebook');
        window.location.href = '/social-auth/facebook?redirect_uri=' + currentUrl;
    }

    loginWithGoogle() {
        const self = this;

        let currentUrl = self.SSUtilService.addOrUpdateUrlParam(window.location.href, 'social_auth', 'google');
        window.location.href = '/social-auth/google?redirect_uri=' + currentUrl;
    }

    compareProperty(propertyId, unitType, unitId) {
        if (!propertyId) {
            return;
        }
        let url = '/tools/compare?property_1_id=' + propertyId;
        if (unitType) {
            url += '&property_1_unit_type=' + unitType;
        }
        if (unitId) {
            url += '&property_1_unit_id=' + unitId;
        }

        window.location.href = url;
    }

    showShareModal(property, unit, viewMode, params = null) {
        const self = this;

        if (typeof property === 'object') {
            self.sharingProperty = property;
        } else {
            let url = "properties/" + property;
            self.SSHttpService.getAPIRequest(url).then(function (response) {
                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);
                    return;
                }

                self.sharingProperty = response.data.property;
           });            
        }

        self.sharingUnit = unit;
        self.viewMode = viewMode;
        
        $('#socialModal').modal('show');
        self.showSocialModal = true;
    }

    getRandomCode(property, unit = null) {
        let self = this;

        self.sharingProperty = property;
        self.sharingUnit = (unit) ? unit : null;

        $('#socialModal').modal('show');
    }

    socialMediaShare($event, media) {
        const self = this;

        let targetElement = $event.currentTarget;
        let link = '';
        let popup = true;
        let email = false;
        let copy = false;

        switch (media) {
            case "facebook":
                link = 'http://www.facebook.com/sharer.php?[title]=Showsuite&u=';
                break;
            case "twitter":
                link = "http://twitter.com/share?text=Showsuite&hashtags=showsuite&url=";
                break;
            case "google":
                link = "https://plus.google.com/share?url=";
                break;
            case "linkedin":
                link = "http://www.linkedin.com/shareArticle?mini=true&url=";
                break;
            case "pinterest":
                link = "http://pinterest.com/pin/create/button/?description=Showsuite&url=";
                break;
            case "tumblr":
                link = "http://www.tumblr.com/share/link?name=Showsuite&&url=";
                break;
            case "email":
                popup = false;
                email = true;
                break;
            case "clipboard":
                popup = false;
                copy = true;
                break;
        }
        let w = 650;
        let h = 400;
        let left = (screen.width / 2) - (w / 2);
        let top = (screen.height / 2) - (h / 2);

        // Page Redirect URL
        let property_named_id = jQuery.isPlainObject(self.sharingProperty) ? self.sharingProperty.named_id : self.sharingProperty;
        let unit_named_id = jQuery.isPlainObject(self.sharingUnit) ? self.sharingUnit.named_id : self.sharingUnit;
        let redirectUrl = self.$window.location.origin + '/' + property_named_id + (unit_named_id ? '/' + unit_named_id : '') + (self.viewMode ? '/' + self.viewMode : '');

        let shareCode = self.SSUtilService.uuid();
        let params = 'media=' + media + '&share_code=' + shareCode;
        if (self.SSUserService.user && self.SSUserService.user.broker) {
            params += '&agent=' + self.SSUserService.user.id;
        }

        if (self.viewMode == 'video') {
            params += '&slide=' + $('#detailed-view-carousel-video .carousel-inner .active').index('#detailed-view-carousel-video .carousel-inner .carousel-item');
        }

        if (!copy) {
            redirectUrl += '?' + encodeURIComponent(params);
        } else {
            redirectUrl += '?' + params;
        }
        let content = link + redirectUrl;

        jQuery('.toolTipTxt').tipsy({
            trigger: 'manual',
            gravity: 'z',
            html: true,
            delayOut: 300,
            fade: true,
            title: 'socialShare',
        });

        if (popup) {
            let pop = window.open(content, 'Sharing ' + self.sharingProperty.name + 'on Social Media', 'width=' + w + ',height=' + h + ',resizable=yes,top=' + top + ',left=' + left);
            pop.onunload = function () {
                pop.onbeforeunload = function (e) {
                    let ele = jQuery(targetElement);
                    jQuery(ele).tipsy("show");
                    setTimeout(function () {
                        jQuery(ele).tipsy("hide");
                    }, 3000);
                };
            };
            pop.focus();

        } else if (email) {
            let subject = "";
            if (self.sharingUnit != null) {
                subject = self.sharingProperty.name + " " + self.SSUtilService.getFullUnitNumber(self.sharingUnit);
            } else {
                subject = self.sharingProperty.name;
            }
            self.$window.location.href = "mailto:?subject=" + subject + "&body=" + content;

        } else if (copy) {
            try {
                let textinput = jQuery('<input type="text" id="socialShareLinkCopy" value="' + content + '"/>');
                textinput.insertAfter(jQuery(".btn-social-share-copy"));
                var clipboard = new Clipboard('.btn-social-share-copy');
                clipboard.on('success', function (e) {
                    e.clearSelection();
                    jQuery('#socialShareLinkCopy').remove();

                    let ele = jQuery(targetElement);
                    jQuery(ele).tipsy("show");
                    setTimeout(function () {
                        jQuery(ele).tipsy("hide");
                    }, 3000);
                });
                clipboard.on('error', function (e) {
                    textinput.remove();
                });
            } catch (err) {
                console.error('Oops, unable to copy');
                console.error(err);
            }
        }

        self.saveSharedLink(property_named_id, unit_named_id, shareCode, content, media);
    }

    saveSharedLink(property_named_id, unit_named_id, shareCode, url, media) {
        let self = this;

        let params = {
            share_code: shareCode,
            property_id: property_named_id,
            unit_id: unit_named_id,
            social_media: media,
            url: url,
        };

        self.SSHttpService.postAPIRequest('share/create', params).then(
            function (responseObj) {
                if (responseObj instanceof Error) {
                    console.error('Error on updating shared links activities into db');
                    return;
                }
            }
        );
    }

    isPropertyProductMarketingSuite(property_product_type) {
        const self = this;

        return property_product_type == self.PROPERTY_PRODUCT_MARKETING_SUITE_1D || property_product_type == self.PROPERTY_PRODUCT_MARKETING_SUITE_2D || property_product_type == self.PROPERTY_PRODUCT_MARKETING_SUITE_3D;
    }

    isPropertyProductManagementSuite(property_product_type) {
        const self = this;
        return property_product_type == self.PROPERTY_PRODUCT_MANAGEMENT_SUITE_AGENT_BOOKING || property_product_type == self.PROPERTY_PRODUCT_MANAGEMENT_SUITE_BUYER_BOOKING;
    }

    // Property Settings
    getPropertySetting(property, name) {
        const self = this;

        if (!property || !property.settings)
            return;

        let propertySetting = null;
        jQuery.each(property.settings, function (i, e) {
            if (e.name == name) {
                propertySetting = e;
                return false;
            }
        });

        return propertySetting;
    }

    getPropertySettingValue(property, name, defaultValue) {
        const self = this;

        let propertySetting = self.getPropertySetting(property, name);
        if (propertySetting) {
            return propertySetting.value;
        } else {
            return defaultValue;
        }
    }

    isPropertySettingEnabled(property, name, defaultValue) {
        const self = this;

        let value = self.getPropertySettingValue(property, name, defaultValue);

        return value == 1;
    }

    // Property Settings

    getStackName(stack_id, stacks) {
        const self = this;

        if (stacks && stacks.length > 0) {
            let found_stack = stacks.find(x => x.id == stack_id);

            if (found_stack && found_stack.hasOwnProperty("name")) {
                return found_stack.name;
            }
        }

        return "";
    }

    checkIsOrganicOrigin() {
        const self = this;

        let url = document.referrer;

        if (url) {
            url = url.split("/")[2]; // Get the hostname
            let parsed = psl.parse(url);

            if (parsed.domain && parsed.domain != "showsuite.com") {
                let url = 'analytics/post';
                let params = {
                    'event': 'views_general',
                    'custom_param1': JSON.stringify({'third_party_referer': parsed.domain})
                };

                self.SSHttpService.getAPIRequest(url, params).then(
                    function (responseObj) {
                        if (responseObj instanceof Error) {
                            console.error(responseObj);
                            return;
                        }
                    }
                );
            }
        }
    }

}

SSAppService.$inject = ['$log', '$window', '$localStorage', 'SSUtilService', 'SSHttpService', 'SSUserService', 'SSConfigService'];
app.service('SSAppService', SSAppService);
